<ng-container *ngIf="!isIe; else downloadBrowser">
  <ds-notifications></ds-notifications>
  <ds-spinner [mode]="1"></ds-spinner>
  <router-outlet></router-outlet>
</ng-container>

<ng-template #downloadBrowser>
  <div class="ieBody h-screen flex flex-col">
    <div class="flex-1">
      <div class="pt-40 text-3xl text-center font-normal">To use the Deeper Signals platform, a modern browser is required for security, reliability, and performance.</div>
    </div>
    <div class="flex flex-1">
      <div class="flex-1 justify-center">
        <div class="text-2xl text-center font-medium">Download for free:</div>
        <div class="flex justify-center pt-6">
          <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" class="cursor-pointer inline-block"
            ><img src="assets/images/firefox-logo.svg" alt="Mozilla Firefox" class="w-24 h-24" />
            <div class="text-xl text-center font-normal">Mozilla</div>
            <div class="text-2xl text-center font-normal">Firefox</div>
          </a>
          <a href="https://brave.com/" target="_blank" class="cursor-pointer inline-block px-10"
            ><img src="assets/images/brave-logo.svg" alt="Brave Browser" class="w-24 h-24" />
            <div class="text-2xl text-center font-normal">Brave</div>
          </a>
          <a href="https://www.google.com/chrome/" target="_blank" class="cursor-pointer inline-block"
            ><img src="assets/images/chrome-logo.svg" alt="Google Chrome" class="w-24 h-24" />
            <div class="text-xl text-center font-normal">Google</div>
            <div class="text-2xl text-center font-normal">Chrome</div>
          </a>
        </div>
      </div>
      <div class="flex-1 justify-center">
        <div class="text-2xl text-center font-medium">Included in the OS</div>
        <div class="flex justify-center pt-6">
          <a href="https://www.microsoft.com/en-us/edge" target="_blank" class="cursor-pointer inline-block pr-10"
            ><img src="assets/images/edge-logo.svg" alt="Microsoft Edge" class="w-24 h-24" />
            <div class="text-xl text-center font-normal">Windows 10</div>
            <div class="text-2xl text-center font-normal">Edge</div>
          </a>
          <a href="https://support.apple.com/en_AU/downloads/safari" target="_blank" class="cursor-pointer inline-block"
            ><img src="assets/images/safari-logo.svg" alt="Safari" class="w-24 h-24" />
            <div class="text-xl text-center font-normal">macOS</div>
            <div class="text-2xl text-center font-normal">Safari</div>
          </a>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="pt-16 text-lg text-center font-normal">Using a modern browser, click on your invite link again or the login link above.</div>
    </div>
  </div>
</ng-template>

import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { DsInputButtonOptions } from "./ds-input.models";
import { NgClass, NgIf } from "@angular/common";
import { DsIconComponent, DsIconEnum, DsIconWeightEnum } from "../ds-icon";
import { DsInputErrorsComponent } from "../ds-input-errors";

@Component({
  selector: "ds-input",
  templateUrl: "./ds-input.component.html",
  standalone: true,
  imports: [NgIf, NgClass, DsIconComponent, DsInputErrorsComponent],
})
export class DsInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild("input", { static: true }) input: ElementRef;

  @Input() name: string;
  @Input() type: "text" | "password" | "hidden" | "search" | "color" | "number" | "range" = "text";
  @Input() placeholder: string = "";
  @Input() prefix: string;
  @Input() icon: string;
  @Input() iconName: string;
  @Input() styleClass: string;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() displayLengthCount: boolean = false;
  @Input() lengthCount: number;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() id: string;
  @Input() suffix: string;
  @Input() customClass: any;
  /**
   * Button inputs
   */
  @Input() showButton: boolean = false;
  @Input() buttonOptions: DsInputButtonOptions;
  @Input() loading: boolean = false;

  @Output() onEnter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() resetSearchEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onBlurEvent: EventEmitter<void> = new EventEmitter<void>();
  focused: boolean = false;
  _disabled: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  DsIconEnum = DsIconEnum;
  DsIconWeightEnum = DsIconWeightEnum;

  constructor(@Self() @Optional() public control: NgControl, private sanitizer: DomSanitizer) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  /**
   * Control has errors
   *
   */
  get hasErrors() {
    return this.control && this.control.errors && (this.control.touched || this.control.dirty);
  }

  ngOnInit() {}

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
    this.onBlurEvent.emit();
    this.onTouched();
  }

  writeValue(value: any): void {
    this.input.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  parseIcon(svg: string) {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  onEnterPressed() {
    this.onEnter.emit(true);
  }
  checkOnChange(event: EventTarget) {
    let val: string | number = (event as HTMLInputElement).value;
    if (val === "") {
      this.resetSearchEvent.emit(true);
    }
    if (this.type === "number") {
      val = +val;
      if (this.hasRequiredValidator(this.control.control) && !val) {
        val = null;
      }
    }
    this.onChange(val);
  }

  hasRequiredValidator(control: AbstractControl): boolean {
    const validatorFn = control.validator;
    if (!validatorFn) return false;

    // Check if the validator function considers null as invalid (required case)
    const result = validatorFn(new FormControl(null));
    return result && result.required === true;
  }
}

<div *ngIf="showModal" [ngClass]="mainWrapperClasses">
  <div class="flex items-end justify-center min-h-screen text-center sm:block sm:p-0" [ngClass]="getSizeModalClasses()">
    <div class="fixed inset-0 transition-opacity" [ngClass]="backgroundClass" (click)="onBackgroundClose()"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <div [id]="id" class="inline-block align-bottom bg-white text-left overflow-auto shadow-xl transform transition-all sm:align-middle sm:w-full" [ngClass]="getModalClasses()" (scroll)="onModalScroll($event)" #modalWrapper>
      <div *ngIf="showCloseButton" class="block absolute top-0 right-0 z-50 pt-4 pr-4">
        <button type="button" (click)="buttonClose()" [ngClass]="closeButtonClasses">
          <span class="sr-only">Close</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>

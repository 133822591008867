import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { DsNotification } from "./ds-notification.models";
import { getPausableTimer } from "./ds-notification.util";

@Injectable({
  providedIn: 'root'
})
export class DsNotificationsService {
  private notifications$ = new BehaviorSubject<DsNotification[]>([]);

  public getNotifications(): Observable<DsNotification[]> {
    return this.notifications$;
  }

  public addNotification(notification: DsNotification) {
    if (notification && notification.options && notification.options.timeout) {
      notification.obs = getPausableTimer(notification.options.timeout, notification.paused);
      notification.obs.completeTimer.pipe(tap(() => this.removeNotification(notification.id))).subscribe();
    }
    this.next([...this.notifications$.getValue(), notification]);
  }

  public removeNotification(id: number) {
    this.next(this.notifications$.getValue().filter((_) => _.id != id));
  }

  private next(notifications: DsNotification[]) {
    this.notifications$.next(notifications);
  }
}

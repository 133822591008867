import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByName',
  pure: true,
  standalone: true,
})
export class DsSelectSortByNamePipe implements PipeTransform {
  //array type is any because we don't specificly know what type of array we get.
  transform(array: any[], field: string, disable: boolean): any[] {
    if (!Array.isArray(array)) {
      return [];
    }

    if (!disable) {
      array.sort((a: string | number, b: string | number) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array;
  }
}

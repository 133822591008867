import { createAction, props } from "@ngrx/store";
import { ServerSideEventsModel, ServerSubscriptionMessage, SubscriptionTypeEnum } from "../models/server-side-events.model";
import { HttpErrorResponse } from "@angular/common/http";

export const subscribeToUserTopic = createAction("[Mercure] Subscribe to User Topic", props<{ url: string; subType: SubscriptionTypeEnum }>());
export const subscribeToAccountTopic = createAction("[Mercure] Subscribe to Account Topic", props<{ url: string; subType: SubscriptionTypeEnum }>());
export const addSubscription = createAction("[Mercure] Add Subscription", props<{ subscription: ServerSideEventsModel }>());
export const serverMessageReceived = createAction("[Mercure] Server Message Received", props<{ message: ServerSubscriptionMessage }>());
export const closeConnections = createAction("[Mercure] Close Connections", props<{ events: ServerSideEventsModel[] }>());
export const closeConnection = createAction("[Mercure] Close Connection", props<{ ssEvent: ServerSideEventsModel }>());
export const downloadCenterUpdate = createAction("[Mercure] Download Center Notification Update", props<{ response: boolean }>());
export const userUpdated = createAction("[Mercure] User Updated", props<{ response: boolean }>());
export const accountDeleted = createAction("[Mercure] Account Deleted", props<{ response: boolean }>());
export const accountUpdated = createAction("[Mercure] Account Updated", props<{ response: boolean }>());
export const subscriptionCanceled = createAction("[Mercure] Subscription Canceled", props<{ response: boolean }>());
export const refreshMerqureTokenReq =  createAction("Refresh Mercure Token Request");
export const refreshMerqureTokenReqSuccess =  createAction("Refresh Mercure Token Request Success", props<{ response: any }>());
export const refreshMerqureTokenReqFailure =  createAction("Refresh Mercure Token Request Failure", props<{ error: HttpErrorResponse }>())

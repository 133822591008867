import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SpinnerState } from './ds-spinner.models';

@Injectable({
  providedIn: 'root',
})
export class DsSpinnerService {
    private spinnerState$$: BehaviorSubject<SpinnerState> = new BehaviorSubject<SpinnerState>({isVisible: false});
    public readonly spinnerState$: Observable<SpinnerState> =
    this.spinnerState$$.asObservable();

  constructor() {}

  showGlobalSpinner(text?: string): void {
    const state: SpinnerState = {
      isVisible: true,
      text: text
    };
    this.spinnerState$$.next(state);
  }

  hideGlobalSpinner(): void {
    this.spinnerState$$.next({isVisible: false, text: undefined});
  }
}

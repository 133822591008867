import { HttpErrorResponse } from "@angular/common/http";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { AccountThemeModel, ThemeActions } from "core-lib";

export const themeFeatureKey = "theme";

export interface ThemeState {
  theme: AccountThemeModel;
  error: HttpErrorResponse;
  loading: boolean;
}

export const initialState: ThemeState = {
  theme: null,
  error: null,
  loading: false,
};

export const selectThemeState = createFeatureSelector<ThemeState>(themeFeatureKey);
export const selectAccountLogo = createSelector(selectThemeState, (state) => state.theme?.image);
export const selectDefaultPrivacyPolicy = createSelector(selectThemeState, (state) => state.theme.default_privacy_policy ?? "https://deepersignals.com/privacy-policy");

export const themeReducer = createReducer(
  initialState,
  on(ThemeActions.updateTheme, (state, { request }) => ({
    ...state,
    theme: request,
  })),
  on(ThemeActions.updateThemeLogo, (state, { logo }) => ({
    ...state,
    theme: {...state.theme, image: logo},
  })),
);

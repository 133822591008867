<div class="flex items-center border-b border-gray-300 py-4 px-6 gap-x-3">
  <ds-icon [name]="DsIconEnum.Xmark" [webfontWeight]="DsIconWeightEnum.Light" webfontCustomClasses="cursor-pointer text-xl" (click)="close()"></ds-icon>
  <div class="flex justify-center flex-grow">Choose a language</div>
  <div class="w-8 h-8"></div>
</div>
<div class="p-6 grid gap-4 grid-cols-3">
  <div
    class="p-3 border rounded-lg shadow-sm cursor-pointer"
    *ngFor="let language of availableLanguages() | order : 'name'"
    [ngClass]="{ 'ring-2 border-none ring-primary-600 flex gap-3 items-center': language.code === currentLanguage(), 'border-gray-200': language.code !== currentLanguage() }"
    (click)="choose(language)">
    <span class="text-sm leading-5 text-gray-700" [ngClass]="{ 'font-medium flex-grow': language.code === currentLanguage(), 'font-normal': language.code !== currentLanguage() }">{{ language.name }}</span>
    <ds-icon [name]="DsIconEnum.Check" [webfontWeight]="DsIconWeightEnum.Regular" webfontCustomClasses="text-primary-600 text-xl" *ngIf="language.code === currentLanguage()"></ds-icon>
  </div>
</div>

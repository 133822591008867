import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, Input, Optional, Output, Self, Signal, ViewChild, ViewEncapsulation, WritableSignal, computed, input, signal } from "@angular/core";
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from "@angular/forms";
import { NgSelectComponent, NgSelectConfig, NgSelectModule } from "@ng-select/ng-select";
import { DsInputErrorsComponent } from "../ds-input-errors";
import { DsButtonComponent } from "../ds-button";
import { DsSelectActiveOnTopPipe } from "./ds-select-active.pipe";
import { DsSelectSortByNamePipe } from "./ds-select.name.pipe";
import { sortArrayBySearchTerm } from "core-lib";

@Component({
  selector: "ds-select",
  templateUrl: "./ds-select.component.html",
  styleUrls: ["./ds-select.component.scss"],
  standalone: true,
  imports: [NgClass, NgIf, NgTemplateOutlet, DsInputErrorsComponent, DsButtonComponent, NgSelectModule, FormsModule, ReactiveFormsModule, DsSelectActiveOnTopPipe, DsSelectSortByNamePipe],
  encapsulation: ViewEncapsulation.None,
})
export class DsSelectComponent implements ControlValueAccessor {
  @Input() model;
  @Input() nameField: string = "name";
  @Input() valueField: string = "value";
  @Input() name: string;
  @Input() placeholder: string = "Select";
  @Input() styleClass: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() showSpinner: boolean = false;

  @Input() showSearch: boolean = false;
  @Input() multiple: boolean = false;
  @Input() maxSelectedItems: number = 99; // Do not limit in case param is not passed
  @Input() showSelectAll: boolean;
  @Input() menuClasses: string;
  @Input() enableAddOnSearch: boolean = false;
  @Input() searchPlaceholder: string = "Search";
  @Input() enableCheckmark: boolean = true;
  @Input() showNumberOfSelectedValues: boolean = false;
  @Input() relative: boolean = false;
  @Input() selectClasses: string = "mt-1";
  @Input() showPillSearch: boolean = false;
  @Input() loading: boolean = false;
  @Input() hideSelected: boolean = false;
  @Input() deselectOnClick: boolean = true;
  suffix: string;
  value: any;

  options = input<any[]>();
  disableNameOrder = input<boolean>(false);
  addedTags: WritableSignal<any[]> = signal([]);
  searchTerm: WritableSignal<string> = signal(null);

  disableNameOrderCheck: Signal<boolean> = computed(() => {
    return !!this.searchTerm() ? true : this.disableNameOrder();
  });

  filteredOptions: Signal<any[]> = computed(() => {
    const options = [...this.options(), ...this.addedTags()];
    if (!this.searchTerm()) return options;

    return sortArrayBySearchTerm(
      options.filter((o) => o[this.nameField]?.toLowerCase().startsWith(this.searchTerm().toLowerCase()) || o[this.nameField]?.toLowerCase().includes(this.searchTerm().toLowerCase())),
      this.nameField,
      this.searchTerm(),
    );
  });

  // get deselectOnClick(): boolean {
  //   return (
  //     !this.control?.control?.hasValidator(Validators.required) &&
  //     !this.multiple &&
  //     this.deselectOnClick
  //   );
  // }

  @ViewChild(NgSelectComponent) ngSelectComponent!: NgSelectComponent;

  isChanged: boolean = false;

  get hasValueHidden(): boolean {
    if (this.multiple) {
      return this._value?.includes("hidden");
    }
    return false;
  }

  _onChange = (event: any) => {};
  _onTouched = (_: any) => {};
  _value: any;
  _disabled: boolean;

  @Output() onClickAddButton: EventEmitter<string> = new EventEmitter<string>();

  @Input() mode: "select" | "dropdown" = "select";

  constructor(@Self() @Optional() public control: NgControl, private config: NgSelectConfig) {
    this.config.appendTo = "body";
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  //setting _value to hidden if is multiple and does not have default value
  //this is workaround because ng-select does not support label template if there is no selected value
  //https://stackoverflow.com/questions/68801503/how-to-display-a-template-in-ng-select-all-the-time
  writeValue(value: any): void {
    if (this.multiple && !value && !this.showPillSearch) {
      this._value = ["hidden"];
    } else {
      this._value = value;
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  //all above methods rewrite to use hidden value if there is no default value or selected options on multiple select
  //because we want to display custom placeholder with arrows and this is the only way to achieve it
  onChangeFn() {
    if (this.multiple && (this._value.length > 0 || this.showPillSearch)) {
      this.filterHidden();
    } else if (this.multiple && this._value.length === 0) {
      this._value = ["hidden"];
    }
    if (this.showPillSearch) {
      this.isChanged = true;
      this.applyValue(this._value);
    }
    this.isChanged = true;
  }
  applyValue(value: any) {
    if (this.isChanged) {
      this.searchTerm.set(null);
      if (this.multiple) {
        let valueWithoutHidden = this._value?.filter((x) => x !== "hidden");
        this.changeValue(valueWithoutHidden);
      } else {
        this.changeValue(value);
      }
    }
  }

  addTag(term: string): any {
    /* https://github.com/ng-select/ng-select/issues/809 */
    if (!this.enableAddOnSearch) return false;
    // @todo-j @todo-m - hack for now until we implement new select  with add functionality (ideally this list is handled externally from this component)
    this.addedTags.set([...this.addedTags(), { [this.valueField]: term, [this.nameField]: term }]);
    return { [this.valueField]: term, [this.nameField]: term };
  }

  getSelectInput(value: string): void {
    this.searchTerm.set(value);
  }

  private changeValue(value: any) {
    this._onChange(value);
  }

  private filterHidden() {
    this._value = this._value?.filter((x) => x !== "hidden");
  }
}

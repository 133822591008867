<div class="p-2 flex items-center gap-x-3 cursor-pointer" (click)="openLanguageModal()">
  <ds-icon [name]="DsIconEnum.Globe" [webfontWeight]="isChooseLanguageModalOpen() ? DsIconWeightEnum.Solid : DsIconWeightEnum.Regular" webfontCustomClasses="text-gray-600 text-sm"></ds-icon>
  <span class="text-sm leading-5 font-medium text-gray-800">
    {{ currentLanguageName() }}
  </span>
</div>

<ds-modal [id]="LanguageSwitcherModalEnum.ChooseLanguageModal" zIndex="z-50" paddingClasses="p-0" (backdropClose)="(false)" (onClose)="onLanguageModalClose()">
  <ds-choose-language-component [enableUserLocaleUpdate]="enableUserLocaleUpdate" *ngIf="isChooseLanguageModalOpen()"></ds-choose-language-component>
</ds-modal>

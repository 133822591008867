<!-- @TODO-j see what we are going to about translate -->

<ng-container *ngIf="hasErrors">
  <ng-container [ngSwitch]="error">
    <div *ngSwitchCase="DsInputErrorsEnum.Required" class="mt-2 text-sm text-red-600">
      {{ "app.form.validationMsg.thisFieldIsRequired" | translate }}
    </div>
    <div *ngSwitchCase="DsInputErrorsEnum.RequiredTrue" class="mt-2 text-sm text-red-600">
      {{ "app.form.validationMsg.thisFieldIsRequired" | translate }}
    </div>
    <div *ngSwitchCase="DsInputErrorsEnum.MinLength" class="mt-2 text-sm text-red-600">Minimum length is {{ errors["minlength"]?.requiredLength }}</div>
    <div *ngSwitchCase="DsInputErrorsEnum.MaxLength" class="mt-2 text-sm text-red-600">Maximum length is {{ errors["maxlength"]?.requiredLength }}</div>
    <div *ngSwitchCase="DsInputErrorsEnum.Min" class="mt-2 text-sm text-red-600">Minimum value is {{ errors["min"]?.min }}</div>
    <div *ngSwitchCase="DsInputErrorsEnum.Max" class="mt-2 text-sm text-red-600">Maximum value is {{ errors["max"]?.max }}</div>
    <div *ngSwitchCase="DsInputErrorsEnum.Pattern" class="mt-2 text-sm text-red-600">Invalid format</div>
    <div *ngSwitchCase="DsInputErrorsEnum.ArrayValueRequired" class="mt-2 text-sm text-red-600">Choose at least one item</div>
    <div *ngSwitchCase="DsInputErrorsEnum.Email" class="mt-2 text-sm text-red-600">
      {{ "app.form.validationMsg.enterValidEmailAddress" | translate }}
    </div>
    <div *ngSwitchCase="DsInputErrorsEnum.PasswordPattern" class="mt-2 text-sm text-red-600">
      {{ "app.form.validationMsg.strongPasswordPattern" | translate }}
    </div>

    <div *ngSwitchCase="DsInputErrorsEnum.Same" class="mt-2 text-sm text-red-600">
      {{ "app.form.validationMsg.newPasswordNotSameAsOld" | translate }}
    </div>
    <div *ngSwitchCase="DsInputErrorsEnum.Whitespace" class="mt-2 text-sm text-red-600">{{ "app.form.validationMsg.whitespace" | translate }}</div>
    <div *ngSwitchCase="DsInputErrorsEnum.NotSame" class="mt-2 text-sm text-red-600">{{ "app.form.validationMsg.confirmPasswordValidation" | translate }}</div>
    <div *ngSwitchCase="DsInputErrorsEnum.DatePattern" class="mt-2 text-sm text-red-600">Not a valid date format</div>
    <div *ngSwitchCase="DsInputErrorsEnum.InvalidDomain" class="mt-2 text-sm text-red-600">Allowed email domains: {{ errors["invalidDomain"]?.domains.join(", ") }}</div>
    <div *ngSwitchCase="DsInputErrorsEnum.SpecialCharacter" class="mt-2 text-sm text-red-600">Special characters are not allowed</div>

    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</ng-container>

<button type="button" [disabled]="disabled" [ngClass]="getFullBtnClasses()" class="inline-flex items-center font-medium rounded shadow-sm text-white" (click)="onButtonClick()" *ngIf="!icon && type === 'primary'">
  {{ name }}
  <svg class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" *ngIf="loading">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</button>

<button type="button" [disabled]="disabled" [ngClass]="getFullButtonIconClasses()" class="inline-flex items-center shadow-sm font-medium rounded-md text-white" *ngIf="icon && !iconOnlyButton && type === 'primary'" (click)="onButtonClick()">
  @if(iconPositionStart){
  <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  }
  <span [ngClass]="textClasses">{{ name }}</span>
  @if(!iconPositionStart){
  <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  }
</button>

<button type="button" [disabled]="disabled" [ngClass]="getFullOnlyIconBtnClasses()" class="inline-flex items-center rounded-full shadow-sm text-white" *ngIf="iconOnlyButton && type === 'primary'" (click)="onButtonClick()">
  <span [ngClass]="getOnlyIconButtonIconClasses()" [innerHtml]="parseIcon(icon)"></span>
  <svg class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" *ngIf="loading">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</button>

<button type="button" [disabled]="disabled" [ngClass]="getFullBtnClasses()" class="inline-flex items-center shadow-sm font-medium rounded text-gray-700 border border-gray-300" (click)="onButtonClick()" *ngIf="!icon && type === 'secondary'">
  {{ name }}
  <svg class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" *ngIf="loading">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</button>

<button type="button" [disabled]="disabled" [ngClass]="getFullButtonIconClasses()" class="inline-flex items-center shadow-sm font-medium rounded text-gray-700 border border-gray-300" *ngIf="icon && !iconOnlyButton && type === 'secondary'" (click)="onButtonClick()">
  @if(iconPositionStart){
  <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  }
  <span [ngClass]="textClasses"> {{ name }}</span>
  @if(!iconPositionStart){
  <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  }
  <svg class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" *ngIf="loading">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</button>

<button type="button" [disabled]="disabled" [ngClass]="getFullOnlyIconBtnClasses()" class="inline-flex items-center shadow-sm font-medium rounded text-gray-700" *ngIf="iconOnlyButton && type === 'secondary'" (click)="onButtonClick()">
  <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  <svg class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" *ngIf="loading">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</button>

<button type="button" [disabled]="disabled" class="inline-flex items-center" [ngClass]="buttonClasses" *ngIf="type === DsButtonTypeEnum.Link" (click)="onButtonClick()">
  <span *ngIf="name" [ngClass]="textClasses"> {{ name }}</span>
  <svg class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" *ngIf="loading">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</button>

<ng-template #iconTemplate>
  @if(isIconSvg()) {
    <ds-icon [name]="icon" [size]="svgSize" [stroke]="svgStroke" [class]="iconClasses" [isSvgIcon]="true"></ds-icon>
  } @else {
    <ds-icon [name]="icon" [webfontWeight]="iconWeight" [webfontCustomClasses]="iconClasses +' '+iconSize"></ds-icon>
  }
</ng-template>

import { Component, Signal } from "@angular/core";
import { AsyncPipe, NgFor, NgForOf, NgIf } from "@angular/common";

import { DsNotificationsService } from "./ds-notification.service";
import { DsNotificationComponent } from "./notification/ds-notification.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { DsNotification } from "./ds-notification.models";

@Component({
  selector: "ds-notifications",
  templateUrl: "./ds-notifications.component.html",
  standalone: true,
  imports: [NgIf, NgFor, NgForOf, DsNotificationComponent, AsyncPipe],
})
export class DsNotificationsComponent {
  notifications: Signal<DsNotification[]> = toSignal(this._notificationsService.getNotifications());

  constructor(private _notificationsService: DsNotificationsService) {}
}

import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { DsIconComponent, DsSelectComponent, DsSpinnerComponent } from "@deepersignals/components";
import { DsModalComponent } from "@deepersignals/components";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { CommonModule } from "@angular/common";

import { LanguageSwitcherComponent } from "./pages/language-switcher.component";
import { ArrayOrderPipe, LanguageSwitcherEffects, LanguageSwitcherReducers } from "core-lib";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChooseLanguageComponent } from "./components/choose-language/choose-language.component";

@NgModule({
  declarations: [LanguageSwitcherComponent, ChooseLanguageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature({
      name: LanguageSwitcherReducers.languageSwitcherFeatureKey,
      reducer: LanguageSwitcherReducers.languageSwitcherReducer,
    }),
    EffectsModule.forFeature(LanguageSwitcherEffects),
    TranslateModule,
    DsSpinnerComponent,
    DsModalComponent,
    DsSelectComponent,
    DsIconComponent,
    ArrayOrderPipe,
  ],
  exports: [LanguageSwitcherComponent],
})
export class LanguageSwitcherModule {}

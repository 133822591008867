import { Directive, HostBinding, Injectable, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";
import { of, zip } from "rxjs";
import { delay, switchMap, take } from "rxjs/operators";
import { TransitionDirective } from "./transition.directive";

@Injectable()
export class TransitionElementContainer {
  public children: TransitionDirective[] = [];
}

@Directive({
  selector: "[dsTransitionElement]",
  standalone: true,
  providers: [TransitionElementContainer],
})
export class TransitionElementDirective implements OnDestroy, OnChanges, OnInit {
  private hasView = false;

  protected _elementClass: string[] = [];

  @Input() dsTransitionElement: boolean = false;

  @HostBinding("class")
  get elementClass(): string {
    return [...this._elementClass].join(" ");
  }
  set(val: string) {
    this._elementClass = val.split(" ");
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private container: TransitionElementContainer) {}

  ngOnInit(): void {
    this._elementClass.push("hidden");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["dsTransitionElement"]) {
      let element = changes["dsTransitionElement"];
      let value = element.currentValue;
      if (value && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;

        this._elementClass.splice(this._elementClass.indexOf("hidden"), 1);

        of(true)
          .pipe(
            delay(5),
            switchMap(() => {
              return zip(this.container.children.map((c) => c.show()));
            }),
            take(1),
          )
          .subscribe();
      } else if (!value && this.hasView) {
        this._elementClass.push("hidden");

        of(true)
          .pipe(
            delay(5),
            switchMap(() => {
              return zip(this.container.children.map((c) => c.hide()));
            }),
            take(1),
          )
          .subscribe((res) => {
            this.hasView = false;
            this.viewContainer.clear();
          });
      }
    }
  }

  ngOnDestroy(): void {}
}

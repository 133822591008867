import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StoreModule } from "@ngrx/store";
import { AuthHttpInterceptor, CoreLibConfigService, CORE_LIB_CONFIG, CoreRouterStoreModule, TailwindThemeService, initCoreLibConfig, metaReducers, rootReducers, loadCoreJavascriptFiles, initSentry, provideSentryErrorHandler, initTailwindThemeConfig, HttpLoaderFactory, PixelModule } from "core-lib";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { DsButtonComponent, DsIconComponent, DsInputComponent, DsModalComponent, DsNotificationsComponent, DsSpinnerComponent } from "@deepersignals/components";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_LOADER_OPTIONS } from "ng-recaptcha";
import { environment } from "../environments/environment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { CommonModule } from "@angular/common";
import { PageNotFoundModule } from "shared-components-lib";

loadCoreJavascriptFiles(environment);
initSentry(environment);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    CoreRouterStoreModule,
    StoreModule.forRoot(rootReducers, {
      metaReducers,
      runtimeChecks: {
        // strictStateImmutability and strictActionImmutability are enabled by default
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: false,
      },
    }),
    EffectsModule.forRoot([]), // root effects initialization
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [], // Devtools for debugging purpose,
    StoreRouterConnectingModule.forRoot(),
    DsNotificationsComponent,
    DsButtonComponent,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    DsIconComponent,
    DsInputComponent,
    DsModalComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => HttpLoaderFactory(http, environment.crowdinKey),
        deps: [HttpClient],
      },
    }),
    RecaptchaV3Module,
    DsSpinnerComponent,
    PageNotFoundModule,
    PixelModule.forRoot({ pluginEnabled: environment.pixel.enabled, enabled: environment.pixel.trackOnLoad, pixelId: [environment.pixel.key] }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },

    {
      provide: APP_INITIALIZER,
      useFactory: initTailwindThemeConfig,
      deps: [TailwindThemeService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initCoreLibConfig,
      deps: [CoreLibConfigService],
      multi: true,
    },
    provideSentryErrorHandler(environment),
    {
      provide: CORE_LIB_CONFIG,
      useValue: {
        apiUrl: environment.apiUrl,
        intercom: {
          enabled: environment.intercom.enabled,
          api_url: environment.intercom.api_url,
          app_id: environment.intercom.app_id,
        },
      },
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey, // use recaptcha.net script source for some of our users
    },
    {
      //https://github.com/DethAriel/ng-recaptcha#loading-from-a-different-location
      provide: RECAPTCHA_LOADER_OPTIONS,
      useValue: {
        onBeforeLoad(_url) {
          return {
            url: new URL("https://www.recaptcha.net/recaptcha/api.js"), // use recaptcha.net script source for some of our users
          };
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

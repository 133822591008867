/**
 * Makes a script element with provided url as src and appends it to the body
 * @param url
 * @param config
 * @param returnScriptElementIfExists
 *
 * @returns script element
 */
export function loadScript(
  url: string,
  config?: {
    innerHTML?: string;
    id?: string;
    nonce?: string;
    defer?: boolean;
    async?: boolean;
  },
  returnScriptElementIfExists: boolean = true,
): HTMLScriptElement {
  const foundScript = document.querySelector<HTMLScriptElement>(`script[src='${url}']`);

  if (!foundScript) {
    // If the script doesn't exist, it will be created
    console.log("Loading ", url);
    const script = document.createElement("script");
    script.src = url;
    if (config) {
      if (config.innerHTML) script.innerHTML = config.innerHTML;
      if (config.id) script.id = config.id;
      if (config.nonce) script.setAttribute("nonce", config.nonce);
      if (config.defer) script.defer = true;
      else if (config.async) script.async = true;
    }
    document.body.appendChild(script);

    return script;
  } // if the script already exists return it
  else {
    if (returnScriptElementIfExists) return foundScript;
    else return null;
  }
}

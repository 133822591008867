import { BehaviorSubject } from 'rxjs';

export enum DsNotificationTypeEnum {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  DownloadNotification = 'download-notification'
}

export class DsNotification {
  id: number;
  title: string;
  text: string;
  params: string[];
  options: { timeout?: number };
  level: DsNotificationTypeEnum = DsNotificationTypeEnum.Success;
  paused: BehaviorSubject<boolean>;
  obs;
  redirectUrl?: string;
  redirectText?: string;
  constructor(params: any = {}) {
    this.id = params.id ? params.id : new Date().getTime();
    this.paused = new BehaviorSubject<boolean>(false);
    this.title = params.title;
    this.text = params.text;
    this.params = params.params;
    this.options = params.options ? params.options : {};
    this.level = params.level;
    this.redirectUrl = params.redirectUrl;
    this.redirectText = params.redirectText;
  }
}

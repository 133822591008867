import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "order",
  pure: true,
  standalone: true,
})
export class ArrayOrderPipe implements PipeTransform {
  transform(value: any[], orderProp: string): any[] {
    const clone = _.cloneDeep(value);
    const sorted = _.sortBy(clone, orderProp);
    return sorted;
  }
}

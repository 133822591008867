import { Component, Input, OnInit, Signal, signal } from "@angular/core";
import { DsIconEnum, DsIconWeightEnum, DsModalService } from "@deepersignals/components";
import { LanguageSwitcherModalEnum } from "../../models/language-switcher.model";
import { Store } from "@ngrx/store";
import { LanguageModel, LanguageSwitcherActions, LanguageTypeEnum, SetLocaleReq, LanguageSwitcherReducers } from "core-lib";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ds-choose-language-component",
  templateUrl: "./choose-language.component.html",
})
export class ChooseLanguageComponent implements OnInit {
  @Input() enableUserLocaleUpdate: boolean;
  DsIconEnum = DsIconEnum;
  DsIconWeightEnum = DsIconWeightEnum;

  currentLanguage: Signal<string> = signal(this.translateService.currentLang);
  availableLanguages: Signal<LanguageModel[]> = this.store.selectSignal(LanguageSwitcherReducers.selectAvailableLanguages);

  constructor(private modalService: DsModalService, private store: Store, private translateService: TranslateService) {}
  ngOnInit(): void {}

  close(): void {
    this.modalService.close(LanguageSwitcherModalEnum.ChooseLanguageModal);
  }

  choose(language: LanguageModel): void {
    const req: SetLocaleReq = {
      type: LanguageTypeEnum.Explicit,
      locale: language.code,
      sendUpdateLocaleReq: this.enableUserLocaleUpdate,
    };
    this.store.dispatch(LanguageSwitcherActions.setLocale({ payload: req }));
    this.close();
  }
}

export enum DsSpinnerType {
  Spinner = 1,
  Indeterminate = 2,
  Badge = 3,
}

export interface SpinnerState {
  isVisible: boolean;
  text?: string;
}

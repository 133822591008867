import { Directive, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { TransitionElementContainer } from "./transition-element.directive";

import { delay, tap } from "rxjs/operators";

export function isEmptyOrSpaces(str: string) {
  return str === null || str.match(/^ *$/) !== null;
}

@Directive({
  selector: "[dsTransition]",
  standalone: true,
})
export class TransitionDirective implements OnInit, OnDestroy {
  protected _transitionClass: string[] = [];
  protected _elementClass: string[] = [];

  @Input() entering: string = "";

  @Input() enteringFrom: string = "";

  @Input() enteringTo: string = "";

  @Input() duration: number = 100;

  @Input() leaving: string = "";

  @Input() leavingFrom: string = "";

  @Input() leavingTo: string = "";

  @Input("dsTransition") additionalClasses: string = "";

  @HostBinding("class")
  get elementClass(): string {
    return [...this._elementClass, ...this._transitionClass].join(" ");
  }
  set(val: string) {
    this._elementClass = val.split(" ");
  }

  constructor(private container: TransitionElementContainer) {}

  ngOnDestroy(): void {
    this.container.children.splice(this.container.children.indexOf(this), 1);
  }

  ngOnInit(): void {
    this.container.children.push(this);
    this._elementClass.push(...this.additionalClasses.split(" "));

    this._enter();
  }

  private _enter() {
    this._transitionClass.push(this.entering);
    this._transitionClass.push(this.enteringFrom);
  }

  private _enterDone() {
    this._transitionClass.splice(this._transitionClass.indexOf(this.enteringFrom), 1);
    this._transitionClass.push(this.enteringTo);
  }

  show(): Observable<boolean> {
    let obs = of(true);

    if (isEmptyOrSpaces(this.entering) && isEmptyOrSpaces(this.enteringFrom) && isEmptyOrSpaces(this.enteringTo)) return obs;
    return obs.pipe(tap(() => this._enterDone()));
  }

  private _leaveEnter() {
    if (this.entering != this.leaving && !isEmptyOrSpaces(this.entering)) {
      this._transitionClass.splice(this._transitionClass.indexOf(this.entering), 1);
    }

    this._transitionClass.push(this.leaving);
  }

  private _leaveEnd() {
    if (this.enteringTo != this.leavingFrom && !isEmptyOrSpaces(this.enteringTo)) {
      this._transitionClass.splice(this._transitionClass.indexOf(this.enteringTo), 1);
    }

    this._transitionClass.push(this.leavingFrom);
  }

  private _leave() {
    this._transitionClass.splice(this._transitionClass.indexOf(this.leavingFrom), 1);
    this._transitionClass.push(this.leavingTo);
  }

  private _leaveDone() {
    this._transitionClass = [];
  }

  hide(): Observable<boolean> {
    this._leaveEnter();

    let duration = this.duration;
    if (this.leaving.indexOf("duration-") !== -1) {
      let durationItem = this.leaving.split(" ").find((f) => f.indexOf("duration-") !== -1);
      if (durationItem != null) {
        let durationStr = durationItem.substring(9, durationItem.length);
        duration = parseInt(durationStr);
      }
    }

    return of(true).pipe(
      tap(() => this._leave()),
      delay(duration),
      tap(() => this._leaveEnd()),
      tap(() => this._leaveDone()),
    );
  }
}

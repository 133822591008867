import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { LanguageSwitcherActions } from "../actions";
import { LanguageModel, availableLanguages } from "../models";

export const languageSwitcherFeatureKey = "lang";

export interface LanguageSwitcherState {
  availableLanguages?: LanguageModel[];
  isLanguageSet: boolean;
  error: HttpErrorResponse;
  loading: boolean;
}

export const initialState: LanguageSwitcherState = {
  availableLanguages: availableLanguages,
  isLanguageSet: false,
  error: null,
  loading: false,
};

export const selectLanguageSwitcherState = createFeatureSelector<LanguageSwitcherState>(languageSwitcherFeatureKey);
export const selectLangSwitcherLoading = createSelector(selectLanguageSwitcherState, (state) => state.loading);
export const selectLangSwitcherError = createSelector(selectLanguageSwitcherState, (state) => state.error);
export const selectAvailableLanguages = createSelector(selectLanguageSwitcherState, (state) => state.availableLanguages);
export const selectIsLanguageSet = createSelector(selectLanguageSwitcherState, (state) => state.isLanguageSet);

export const languageSwitcherReducer = createReducer(
  initialState,
  on(LanguageSwitcherActions.updateAvailableLanguages, (state, {}) => ({
    ...state,
    loading: true,
  })),
  on(LanguageSwitcherActions.updateAvailableLanguagesSuccess, (state, { payload }) => ({
    ...state,
    availableLanguages: payload,
    loading: false,
  })),
  on(LanguageSwitcherActions.updateAvailableLanguagesFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(LanguageSwitcherActions.setLocale, (state, {}) => ({
    ...state,
    isLanguageSet: false,
    loading: true,
  })),
  on(LanguageSwitcherActions.setLocaleSuccess, (state) => ({
    ...state,
    isLanguageSet: true,
    loading: false,
  })),
  on(LanguageSwitcherActions.setLocaleFailure, (state, { error }) => ({
    ...state,
    error: error,
    isLanguageSet: false,
    loading: false,
  })),
  on(LanguageSwitcherActions.resetToDefaultAvailableLanguage, (state, {}) => ({
    ...state,
    availableLanguages: availableLanguages,
  })),
);

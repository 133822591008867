import { ActionReducer, MetaReducer, ActionReducerMap } from "@ngrx/store";
import { getRouterSelectors, routerReducer, RouterReducerState } from "@ngrx/router-store";

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import { isDevMode } from "@angular/core";
import { UserState, userReducer } from "./user.reducers";
import { ThemeState, themeReducer } from "./theme.reducers";
import { MercureState, mercureReducer } from "./mercure.reducers";

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: RouterReducerState;
  user: UserState;
  theme: ThemeState;
  mercure: MercureState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const rootReducers: ActionReducerMap<State> = {
  router: routerReducer,
  user: userReducer,
  theme: themeReducer,
  mercure: mercureReducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log("prev state", state);
    console.log("action", action);
    console.log("next state", result);
    console.groupEnd();

    return result;
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = isDevMode() ? [logger] : [];

/**
 * Router Selectors
 */
export const { selectRouteData } = getRouterSelectors();

import { createAction, props } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { LanguageModel, SetLocaleReq, UpdateCurrentLocaleRequest } from "../models";

export const updateCurrentLanguage = createAction("[Language] Update Current Language", props<{ payload: UpdateCurrentLocaleRequest }>());
export const updateCurrentLanguageSuccess = createAction("[Language] Update Current Language Success", props<{ payload: string }>());
export const updateCurrentLanguageFailure = createAction("[Language] Update Current Language Failure", props<{ error: HttpErrorResponse }>());

export const updateAvailableLanguages = createAction("[Language] Update Available Languages", props<{ payload: LanguageModel[] }>());
export const updateAvailableLanguagesSuccess = createAction("[Language] Update Available Languages Success", props<{ payload: LanguageModel[] }>());
export const updateAvailableLanguagesFailure = createAction("[Language] Update Available Languages Failure", props<{ error: HttpErrorResponse }>());

export const updateUserLocale = createAction("[Language] Update User Locale", props<{ payload: string }>());
export const updateUserLocaleSuccess = createAction("[Language] Update User Locale Success");
export const updateUserLocaleFailure = createAction("[Language] Update User Locale Failure", props<{ error: HttpErrorResponse }>());

export const setLocale = createAction("[Language] Set Locale", props<{ payload: SetLocaleReq }>());
export const setLocaleSuccess = createAction("[Language] Set Locale Success");
export const setLocaleFailure = createAction("[Language] Set Locale Failure", props<{ error: HttpErrorResponse }>());

export const resetToDefaultAvailableLanguage = createAction("[Language] Reset To Default Available Languages");

import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { DsIconComponent, DsSelectComponent, DsSpinnerComponent } from "@deepersignals/components";
import { DsModalComponent } from "@deepersignals/components";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { CommonModule } from "@angular/common";

import { ArrayOrderPipe } from "core-lib";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PageNotFoundComponent } from "./components/page-not-found.component";
import { LanguageSwitcherModule } from "../language-switcher";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, StoreModule, EffectsModule, TranslateModule, DsSpinnerComponent, DsModalComponent, DsSelectComponent, DsIconComponent, ArrayOrderPipe, LanguageSwitcherModule],
  exports: [PageNotFoundComponent],
})
export class PageNotFoundModule {}

import { InjectionToken } from "@angular/core";
import { CoreLibConfigService } from "./core-lib-config.service";

export interface IntercomConfig {
  enabled: boolean;
  api_url: string;
  app_id: string;
}
export interface CoreLibConfig {
  apiUrl: string;
  quizApiUrl: string;
  mercureUrl: string;
  intercom?: IntercomConfig;
}

export const CORE_LIB_CONFIG = new InjectionToken<CoreLibConfig>("CoreLibConfig");

let _configService: CoreLibConfigService;
export function initConfigService(configService: CoreLibConfigService) {
  _configService = configService;
}
const getConfigValue = (key: keyof CoreLibConfig): string | IntercomConfig => {
  const config = CoreLibConfigService.getConfig();
  return config ? config[key] : null;
};
export const getIntercomConfig = (): IntercomConfig => {
  return getConfigValue("intercom") as IntercomConfig;
};

export const DEFAULT_LANGUAGE: string = "en";

export function initCoreLibConfig(CoreLibConfigService: CoreLibConfigService) {
  return () => CoreLibConfigService;
}

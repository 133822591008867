// In projects/state-lib/src/lib/router-store/router-store.module.ts

import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { rootReducers } from '../reducers';

@NgModule({
  imports: [
    StoreModule.forFeature('router', rootReducers.router),
  ],
})
export class CoreRouterStoreModule {}

import { Component, DestroyRef, OnInit, WritableSignal, inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { CoreSessionStorageService, LanguageSwitcherActions, LanguageTypeEnum, SetLocaleReq } from "core-lib";
import { RouteState } from "../models/page-not-found.models";

@Component({
  selector: "ds-page-not-found",
  templateUrl: "./page-not-found.component.html",
})
export class PageNotFoundComponent implements OnInit {
  previousUrl;
  urlAfterRedirect;

  sameLinks = false;
  linkNoLongerActive: boolean = false;
  message: string;
  isAnon: WritableSignal<boolean> = signal(false);

  private destroyRef: DestroyRef = inject(DestroyRef);
  constructor(private route: ActivatedRoute, private store: Store, private sessionStorageService: CoreSessionStorageService) {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      this.linkNoLongerActive = params.linkNoLongerActive === "true";
      this.message = params.message;
      if (params.isAnon) {
        this.isAnon.set(true);
      }
      if (params.locale) {
        const req: SetLocaleReq = {
          type: LanguageTypeEnum.Explicit,
          locale: params.locale,
        };
        this.store.dispatch(LanguageSwitcherActions.setLocale({ payload: req }));
      }
    });
  }

  ngOnInit(): void {
    this.previousUrl = RouteState.previousRoute;
    this.urlAfterRedirect = RouteState.urlAfterRedirect;
    this.sameLinks = this.previousUrl === this.urlAfterRedirect;
  }
}
